import Axios from "axios"
import moment from "moment"
import React, { useState, useEffect } from "react"
import { period } from "../utilities/utilities"

const LIVETICKER_URL = process.env.GATSBY_LIVETICKER_URL
const LIVETICKER_INTERVAL = process.env.GATSBY_LIVETICKER_POLLING_INTERVAL

const LIVETICKER_TABLE_URL = process.env.GATSBY_LIVETICKER_TABLE_URL
const LIVETICKER_TABLE_INTERVAL =
  process.env.GATSBY_LIVETICKER_TABLE_POLLING_INTERVAL

export const LiveMatchContext = React.createContext()

export const LiveMatchProvider = props => {
  // Data from liveticker match feed
  const [matchState, setMatchState] = useState({
    currentWebsite: props.currentWebsite || "",
    matchId: null,
    isRunning: false,
    liveMatchData: props.matchData || null,
    matchIsLive: false,
    matchIsOver: false,
  })

  // Data from liveticker standings feed
  const [tableData, setTableData] = useState({})

  const today = moment()
  const kickOff = matchState.liveMatchData
    ? moment(matchState.liveMatchData.kickoff)
    : null

  let matchIsToday =
    matchState.liveMatchData &&
    Object.keys(matchState.liveMatchData).length === 0
      ? false
      : today.isSame(kickOff, "day")

  const [showSkeleton, setShowSkeleton] = useState(
    matchState.liveMatchData ? matchIsToday : true
  )

  useEffect(() => {
    if (
      matchState.liveMatchData &&
      matchState.liveMatchData.liveticker_active === true
    ) {
      const interval = setInterval(() => {
        fetchData()
      }, LIVETICKER_INTERVAL)
      fetchData()
      return () => clearInterval(interval)
    }
  }, [matchState.matchIsLive])

  useEffect(() => {
    if (
      matchState.liveMatchData &&
      matchState.liveMatchData.liveticker_active === true
    ) {
      const interval = setInterval(() => {
        fetchTableData()
      }, LIVETICKER_TABLE_INTERVAL)
      fetchTableData()
      return () => clearInterval(interval)
    }
  }, [matchState.matchIsLive])

  useEffect(() => {
    if (matchState.currentWebsite === "profis") {
      init()
    }
  }, [])

  const init = () => {
    console.log("BETA: Liveticker - init")
    fetchData()
    fetchTableData()
  }

  const forceUpdate = () => {
    fetchData()
    fetchTableData()
  }

  const fetchData = async () => {
    console.log("BETA: Liveticker - fetch match data")
    Axios.get(LIVETICKER_URL)
      .then(function(response) {
        const data = response.data.data

        setMatchState({
          currentWebsite: props.currentWebsite || "",
          matchId: data.id,
          isRunning: data.liveticker_active || false,
          liveMatchData: data,
          matchIsLive: data.liveticker_active || false,
          matchIsOver: data.period === period.FULL_TIME,
        })
        setShowSkeleton(false)
      })
      .catch(function(error) {
        console.error(error)
      })
  }

  const fetchTableData = async () => {
    console.log("BETA: Liveticker - fetch table data")
    Axios.get(LIVETICKER_TABLE_URL)
      .then(function(response) {
        const data = response.data.data
        setTableData(data)
      })
      .catch(function(error) {
        console.error(error)
      })
  }

  return (
    <LiveMatchContext.Provider
      value={{ ...matchState, showSkeleton, forceUpdate, tableData }}
    >
      {props.children}
    </LiveMatchContext.Provider>
  )
}
