const MAX_WIDTH_TIMELINE = 100

const period = {
  PREMATCH: "PreMatch",
  LIVE: "Live",
  FIRST_HALF: "FirstHalf",
  SECOND_HALF: "SecondHalf",
  HALF_TIME: "HalfTime",
  EXTRA_FIRST_HALF: "ExtraFirstHalf",
  EXTRA_SECOND_HALF: "ExtraSecondHalf",
  SHOOT_OUT: "ShootOut",
  FULL_TIME: "FullTime",
}

const matchLength = {
  HALF_TIME: 45,
  FULL_TIME: 90,
  EXTRA_HALF_TIME: 105,
  EXTRA_FULL_TIME: 120,
}

const matchStatus = {
  announced: "announced",
  before: "before",
  live: "live",
  after: "after",
}

const eventType = {
  YELLOW_CARD: "YellowCard",
  RED_CARD: "RedCard",
  YELLOW_RED_CARD: "YellowRedCard",
  GOAL: "Goal",
  SUBSTITUTION: "Substitution",
  KICKOFF: "Kickoff",
  WHISTLE_FIRST_HALF: "WhistleFirstHalf",
  KICKOFF_SECOND_HALF: "KickoffSecondHalf",
  FINAL_WHISTLE: "FinalWhistle",
  VIDEO: "VAR",
}

function truncate(n, useWordBoundary) {
  if (this.length <= n) {
    return this
  }
  var subString = this.substr(0, n - 1)
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "&hellip;"
  )
}

function concatStrings() {
  const args = Array.prototype.slice.call(arguments)
  return args.filter(n => n).join(" ")
}

function formatOvertime(time) {
  if (time > 90) {
    return `90' + ${time - 90}'`
  } else {
    return `${time}'`
  }
}
function circleDasharray(full, set) {
  let percent = parseInt((set / full) * 100)
  if (percent) {
    return percent + ",100"
  } else {
    return "0,100"
  }
}

function squadSubNavigation(subnavigation) {
  let sn = []
  // Typo3: Subnavigation
  for (let i in subnavigation) {
    sn.push({
      name: subnavigation[i].name,
      full_slug: subnavigation[i].full_slug,
    })
  }
  return sn
}

function peopleGrouper(position) {
  if (position === "Goalkeeper" || position === "goalkeeper") {
    return "Tor"
  }
  if (position === "Defender" || position === "defender") {
    return "Abwehr"
  }
  if (position === "Midfielder" || position === "midfielder") {
    return "Mittelfeld"
  }
  if (position === "Forward" || position === "forward") {
    return "Sturm"
  }

  if (position === "Manager") {
    return "Trainerteam"
  }
  if (position === "manager") {
    return "Teammanagement"
  }
  if (position === "Assistant Manager") {
    return "Trainerteam"
  }
  if (position === "Goalkeeper Trainer") {
    return "Trainerteam"
  }
  if (position === "Athletic trainer") {
    return "Trainerteam"
  }
  if (position === "Trainer" || position === "trainer") {
    return "Trainerteam"
  }
  if (position === "Fitness Trainer") {
    return "Trainerteam"
  }

  if (position === "Director, Sport") {
    return "Sportliche Leitung"
  }
  if (position === "Scout") {
    return "Sportliche Leitung"
  }
  if (position === "sporting_guidance") {
    return "Sportliche Leitung"
  }
  
  return position
}

// Aus Typo3 kommt der full_slug mal mit und mal ohne führenden Slash
// => hier wird das bereinigt
function clean_fullslug(full_slug) {
  if (full_slug) {
    if (full_slug.startsWith("/")) {
      return full_slug
    } else {
      return "/" + full_slug
    }
  }
  return full_slug
}

export {
  concatStrings,
  matchStatus,
  truncate,
  formatOvertime,
  MAX_WIDTH_TIMELINE,
  matchLength,
  period,
  eventType,
  circleDasharray,
  squadSubNavigation,
  peopleGrouper,
  clean_fullslug,
}
