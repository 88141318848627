/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { LiveMatchProvider } from "gatsby-component-lib/context/LiveMatchContext"
import { I18nContext } from "gatsby-component-lib/context/I18nContext"
import React from "react"

const wrapRootElement = ({ element }, pluginOptions) => {
  return (
    <LiveMatchProvider currentWebsite={pluginOptions.website || ""}>
      {element}
    </LiveMatchProvider>
  )
}

const wrapPageElement = ({ element, props }) => {
  return <I18nContext pageContext={props.pageContext}>{element}</I18nContext>
}

export { wrapRootElement, wrapPageElement }

window.opta_settings = {
  subscription_id: "87125934cbeeabe0962495b572fe430d",
  language: "de_DE",
  timezone: "Europe/London",
}
